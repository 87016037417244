
<div class="w-100 pd-20"  >
  
         <div class=" d-flex justify-content-end align-items-center ">
            <span class="material-symbols-outlined cursor" [ngClass]="close ? ' iconSelected':'iconDeselected' " (click)="closeDrawer()">close</span>
         </div>

        <div class="d-flex justify-content-center mt-30" >
            <span class="material-icons" style="font-size: 100px; color: rgb(244, 8, 47);" *ngIf="!bank?.filePath || bank?.filePath === '' "> account_balance  </span>
            <img [src]="bank | bankLogoPath " class="img-fluid" style="width:40%; height: auto;" *ngIf="bank?.filePath && bank?.filePath !== '' ">
            <!-- <img [src]="img " class="img-fluid" style="width:40%; height: auto;" *ngIf="bank?.filePath && bank?.filePath !== '' "> -->
        </div>

        <form [formGroup]="myForm" class="needs-validation mt-3 font fs-10 "  novalidate  [autocomplete]="false" (ngSubmit)="onSave()" >
            
            <div class="col-12" >
                <label class="text w-100 fs-10" style="font-family: poppins-regular;"> Núm BANCO </label>
                  <div style="text-transform: uppercase; font-family: poppins-regular; font-size: 1.3em;" > {{ bank?.name }} &nbsp;  ({{bank?.bankNumber}})
                </div>
            </div> 
            
            <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Número do agencia <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="rAgencia"   />
                @if(f['rAgencia'].touched && f['rAgencia'].invalid) {
                    <mat-hint class="m-b-16 error-msg">
                    @if(f['rAgencia'].errors && f['rAgencia'].errors['required']) {
                    <div class="error">Dado obrigatorio.</div>
                    } 
                    </mat-hint>
                    }
                </mat-form-field>
            </div>

            <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Número do tipo de conta <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="rTp_cc"  [readOnly]="bank?.rTp_cc === 0" />
                @if(f['rTp_cc'].touched && f['rTp_cc'].invalid) {
                    <mat-hint class="m-b-16 error-msg">
                    @if(f['rTp_cc'].errors && f['rTp_cc'].errors['required']) {
                    <div class="error">Dado obrigatorio.</div>
                    } 
                    </mat-hint>
                    }
                </mat-form-field>
            </div>
     
            <div class="col-12 mt-30" >
                <mat-label class="font f-w-600 m-b-8 d-block">Número de contaa <b style="color: red;">*</b> </mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary"  >
                <input matInput type="text"  formControlName="rNr_cc" />
                @if(f['rNr_cc'].touched && f['rNr_cc'].invalid) {
                    <mat-hint class="m-b-16 error-msg">
                    @if(f['rNr_cc'].errors && f['rNr_cc'].errors['required']) {
                    <div class="error">Dado obrigatorio.</div>
                    } 
                    </mat-hint>
                    }
                </mat-form-field>
            </div>

            <div class="col-12 mt-30">

                <span style="text-transform: uppercase; font-family: poppins-regular; font-size: 1.3em;">Digito validador:  {{digit}} </span>
            
                <span style="color: red; width: 100%; display: block;" *ngIf="showErrorLabel"> (trabajando en este error). Si el digito validador deberia ser "X" arroja un "10" </span>
            </div> 

            <div class="d-flex justify-content-end pd-10">
                <button class="cancelButton" (click)="closeDrawer()"> Cancelar </button> &nbsp;&nbsp;
                <button type="submit" class="acceptButton" > Enviar</button>
            </div>
       
        </form> 
</div>    